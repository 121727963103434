import ConversationAnswer from "../../molecules/conversation-answer";
import React, {ReactNode, useEffect, useRef, useState} from "react";
import MessageType from "../../../lib/MessageType";
import './index.scss';
import BtnChevron, {ChevronDirection} from "../../../../../../../../design/1/js/templates/atoms/btn-chevron";
import {MessageToolView} from "../../../lib/enum/MessageToolView";
import AnswerType from "../../../lib/AnswerType";
import JsonResponse from "../../../../../../../../design/1/js/lib/entity/response/JsonResponse";
import {toast} from "react-hot-toast";
import formatErrorToastMessage from "../../../../../../../../design/1/js/lib/formatErrorToastMessage";

interface MessagetoolConversationProps {
    message: MessageType,
    switchView: (view: MessageToolView) => void;
    updateReadStatusAction: string;
    answerForm: (addAnswer: (answer: AnswerType) => void) => ReactNode;
    isClient?: boolean;
}

const MessagetoolConversation = ({
    message, switchView, updateReadStatusAction, answerForm, isClient,
}: MessagetoolConversationProps) => {
    const baseClassName = 'm-baseConversation';
    const bottomRef = useRef(null);

    useEffect(() => {
        scrollToBottom();
        modifyBaseStyles();
        markAllAsRead();

        // Revert modifications on dismount
        return () => {
            revertStyleModifications();
        }
    }, []);

    const scrollToBottom = () => bottomRef.current?.scrollIntoView({ behavior: 'smooth' });

    const modifyBaseStyles = () => {
        const widgetContainer = document.getElementsByClassName('sv-slot-content')[0] as HTMLDivElement;
        widgetContainer.style.paddingTop = 'var(--spacer-xs)';

        const outerWidgetContainer = document.getElementsByClassName('plf-default-content')[0] as HTMLDivElement;
        outerWidgetContainer.style.width = '100%';

        document.body.classList.add('messageToolConversationView');
    }

    const revertStyleModifications = () => {
        const widgetContainer = document.getElementsByClassName('sv-slot-content')[0] as HTMLDivElement;
        const outerWidgetContainer = document.getElementsByClassName('plf-default-content')[0] as HTMLDivElement;

        widgetContainer.style.removeProperty('padding-top');
        outerWidgetContainer.style.removeProperty('width');

        document.body.classList.remove('messageToolConversationView');
    }

    const returnToInbox = () => {
        switchView(MessageToolView.INBOX);
    }

    const [newAnswerIds, setNewAnswerIds] = useState([]);

    const addAnswer = (answer: AnswerType) => {
        message.answers = [...message.answers, answer];
        setNewAnswerIds(currentIds => [...currentIds, answer.id]);
    };

    const markAllAsRead = () => {
        const unreadAnswersFromOther = message.answers.filter((answer) => {
            return answer.fromMPK === isClient && !answer.read;
        });
        for (const unreadAnswer of unreadAnswersFromOther) {
            fetch(updateReadStatusAction, {
                method: 'POST',
                body: JSON.stringify({ inboxId: message.id, messageId: unreadAnswer.id }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'X-Csrf-Token': document.body.dataset.csrfToken,
                },
            }).then(res => res.json())
                .then((response: JsonResponse) => {
                    if (!response.success) {
                        toast.error(formatErrorToastMessage(window.sv_resource.get('plf_error_unknown'), response.traceId))
                    }
                });
        }
    }

    return (
        <>
            <div className={`${baseClassName}__overlay`}>
                <div className={`${baseClassName}__conversationNav`} onClick={returnToInbox}>
                    <BtnChevron className={`${baseClassName}__chevron`} direction={ChevronDirection.Left} standalone />
                    <div className={`${baseClassName}__conversationInfo`}>
                        <div className={`${baseClassName}__conversationTitle`}>
                            {message.title}
                        </div>
                        {!isClient && (
                            <div>
                                {message.client.clientName} - VersIntId: {message.client.clientVersIntId}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className={`${baseClassName}__panel`}>
                {
                    message.answers.map((answer) => {
                        return (
                            <ConversationAnswer
                                key={`answer-${answer.id}`}
                                answer={answer}
                                isClient={isClient}
                            />
                        );
                    })
                }
            </div>
            {answerForm(addAnswer)}
            <div ref={bottomRef}></div>
        </>
    );
}

MessagetoolConversation.defaultProps = {
    isClient: false,
}

export default MessagetoolConversation;
